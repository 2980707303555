<template>
  <div class="welcome">
    <h2 v-html="data.header"></h2>
    <p v-html="data.text"></p>
    <p class="welcome__timer-warning">При начале тестирования будет запущен таймер отсчета времени, по истечении которого тест будет завершен.</p>
    <button class="button centering m-30" @click="openWarningPopup">
      Начать тестирование
    </button>
    <StartTimerWarningModal
      v-if="isStartTimerWarningModalVisible"
      @close="closeWarningPopup"
      @start="start"
    />
  </div>
</template>

<script>
import StartTimerWarningModal from '@/components/common/StartTimerWarningModal';

export default {
  name: "NewStateComponent",
  components: {
    StartTimerWarningModal,
  },
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
      required: true,
    },
  },
  data() {
    return {
      isStartTimerWarningModalVisible: false,
    };
  },
  methods: {
    openWarningPopup() {
      this.isStartTimerWarningModalVisible = true;
    },
    closeWarningPopup() {
      this.isStartTimerWarningModalVisible = false;
    },
    start() {
      this.closeWarningPopup();
      this.$emit("start");
    }
  }
}
</script>
