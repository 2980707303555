<template>
  <div class="wrapper">
    <template v-if="loading">
      <LoadingComponent/>
    </template>
    <template v-else>
      <div class="testing-state">
        <NewStateComponent
            v-if="state === 'NEW'"
            :data="data"
            @start="start()"
        />
        <PrepareStateComponent
            v-if="state === 'PREPARE'"
            :data="data"
            :timeLeft="timeLeft"
            :timeLimit="timeLimit"
            @start-test="startTest()"
        />
        <BreakStateComponent
            v-if="state === 'BREAK'"
            :data="data"
            :timeLeft="timeLeft"
            :timeLimit="timeLimit"
            @start-section="startSection()"
        />
        <InProcessStateComponent
            v-if="state === 'IN_PROCESS'"
            :id="id"
            :data="data"
            :timeLeft="timeLeft"
            :timeLimit="timeLimit"
            @post-answers="postAnswers($event)"
        />
        <CompleteStateComponent
            v-if="state === 'COMPLETE'"
            :data="data"
        />
      </div>
    </template>
  </div>
</template>

<script>
import axios from 'axios';
import LoadingComponent from '@/components/common/LoadingComponent';
import NewStateComponent from '@/components/stages/NewStateComponent';
import PrepareStateComponent from '@/components/stages/PrepareStateComponent';
import BreakStateComponent from '@/components/stages/BreakStateComponent';
import InProcessStateComponent from '@/components/stages/InProcessStateComponent';
import CompleteStateComponent from '@/components/stages/CompleteStateComponent';
import { clearQuestionIndex, clearStoredData } from '@/helpers/answerUtils.js';


export default {
  name: "TestingComponent",
  components: {
    LoadingComponent,
    NewStateComponent,
    PrepareStateComponent,
    BreakStateComponent,
    InProcessStateComponent,
    CompleteStateComponent
  },

  data() {
    return {
      id: '',
      loading: false,
      state: '',
      data: {},
      timeLeft: 0,
      timeLimit: 0,
    };
  },

  created() {
    this.id = this.$route.params.id;
    if (!this.id) {
      this.$router.push('/404');
    }
    this.updateData();
    window.addEventListener('focus', this.handleFocusPage);
  },

  methods: {
    handleFocusPage() {
      const hasTimer = this.state === 'PREPARE'
          || this.state === 'BREAK'
          || this.state === 'IN_PROCESS'
      if (hasTimer) {
        this.updateData();
      }
    },

    prepareData(sourceData) {
      const { state, data, stageTamer } = sourceData;
      this.state = state;
      this.data = data;
      this.timeLeft = stageTamer.timeLeft;
      this.timeLimit = stageTamer.timeLimit;
    },

    async updateData() {
      this.loading = true;
      await axios.get('/api/testing/stage/' + this.id)
      .then(r => {
        const { data } = r;
        this.prepareData(data);
        this.loading = false;
      }).catch(() => {
        this.$router.push('/404');
      })
    },

    async start() {
      this.loading = true;
      await axios.get('/api/testing/start/' + this.id)
      .then(() => {
        this.updateData();
      });
    },

    async startTest() {
      this.loading = true;
      await axios.get('/api/testing/start-test/' + this.id)
       .then(() => {
         this.updateData();
       });
    },

    async startSection() {
      this.loading = true;
      await axios.get('/api/testing/start-section/' + this.id)
          .then(() => {
            this.updateData();
          });
    },

    async postAnswers(answers) {
      this.loading = true;
      const data = {
        answers,
      }
      await axios.post('/api/testing/stage/' + this.id, data)
       .then(() => {
         this.updateData();
         clearQuestionIndex();
         clearStoredData();
       });
    },
  },
};
</script>
