<template>
  <div class="not-found">Ошибка 404</div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
  .not-found {
    color: #fff;
    font-size: 25px;
    margin-top: 30px;
    font-weight: 300;
  }
</style>