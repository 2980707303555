<template>
  <div class="break">
    <h2>{{ data.text }}</h2>
    <TimerComponent
        :initialTimeLimit="timeLimit"
        :initialTimeLeft="timeLeft"
        @timesUp="startSection()"
    />
    <button class="button centering m-30" @click="startSection()">Я отдохнул, готов к прохождению теста</button>
  </div>
</template>

<script>
import TimerComponent from '@/components/common/TimerComponent';

export default {
  name: "BreakStateComponent",

  components: {
    TimerComponent,
  },

  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
      required: true,
    },
    timeLeft: {
      type: Number,
      default() {
        return 0;
      },
    },
    timeLimit: {
      type: Number,
      default() {
        return 0;
      },
    },
  },

  methods: {
    startSection() {
      this.$emit("start-section");
    }
  }
}
</script>
