<template>
  <div class="break">
    <p>Спасибо за прохождение теста</p>
    <a
        v-if="data.person"
        href="https://lk.skillcode.pro/">
      <button
          class="button centering m-30">Вернуться в личный кабинет
      </button>
    </a>
  </div>
</template>

<script>
export default {
  name: "CompleteStateComponent",
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
      required: true,
    },
  },
}
</script>
