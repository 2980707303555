<template>
  <div class="test-timer">
    <svg class="test-timer__svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
      <g class="test-timer__circle">
        <circle class="test-timer__path-elapsed" cx="50" cy="50" r="45" />
        <path
          :stroke-dasharray="circleDasharray"
          class="test-timer__path-remaining"
          :class="remainingPathColor"
          d="
            M 50, 50
            m -45, 0
            a 45,45 0 1,0 90,0
            a 45,45 0 1,0 -90,0
          "
        />
      </g>
    </svg>
    <span class="test-timer__label">{{ formattedTimeLeft }}</span>
  </div>
</template>

<script>
export default {
  props: {
    initialTimeLimit: Number,
    initialTimeLeft: Number
  },
  data() {
    return {
      timePassed: 0,
      timerInterval: null,
      FULL_DASH_ARRAY: 283,
      TIME_LIMIT: null,
      COLOR_CODES: {
        info: {
          color: "green"
        },
        warning: {
          color: "orange",
          threshold: 0
        },
        alert: {
          color: "red",
          threshold: 0
        }
      }
    };
  },

  computed: {
    circleDasharray() {
      return `${(this.timeFraction * this.FULL_DASH_ARRAY).toFixed(0)} 283`;
    },

    formattedTimeLeft() {
      const timeLeft = this.timeLeft;
      const minutes = Math.floor(timeLeft / 60);
      let seconds = timeLeft % 60;

      if (seconds < 10) {
        seconds = `0${seconds}`;
      }

      return `${minutes}:${seconds}`;
    },

    timeLeft() {
      return this.TIME_LIMIT - this.timePassed;
    },

    timeFraction() {
      const rawTimeFraction = this.timeLeft / this.TIME_LIMIT;
      return rawTimeFraction - (1 / this.TIME_LIMIT) * (1 - rawTimeFraction);
    },

    remainingPathColor() {
      const { alert, warning, info } = this.COLOR_CODES;

      if (this.timeLeft <= alert.threshold) {
        return alert.color;
      } else if (this.timeLeft <= warning.threshold) {
        return warning.color;
      } else {
        return info.color;
      }
    }
  },

  watch: {
    timeLeft(newValue) {
      if (newValue <= 300) {
        this.readyToContinue();
      }
      if (newValue <= 0) {
        this.onTimesUp();
      }
    }
  },

  mounted() {
    this.TIME_LIMIT = this.initialTimeLimit;
    this.timePassed = this.initialTimeLimit - this.initialTimeLeft;
    this.COLOR_CODES.warning.threshold = this.initialTimeLimit / 2;
    this.COLOR_CODES.alert.threshold = this.initialTimeLimit / 4;
    this.startTimer();
  },

  methods: {
    onTimesUp() {
      this.$emit("timesUp");
      clearInterval(this.timerInterval);
    },

    readyToContinue() {
      this.$emit("readyToContinue");
    },

    startTimer() {
      this.timerInterval = setInterval(() => (this.timePassed += 1), 1000);
    }
  }
};
</script>

<style scoped lang="scss">
.test-timer {
  position: relative;
  width: 90px;
  height: 90px;

  &__svg {
    transform: scaleX(-1);
  }

  &__circle {
    fill: none;
    stroke: none;
  }

  &__path-elapsed {
    stroke-width: 7px;
    stroke: grey;
  }

  &__path-remaining {
    stroke-width: 7px;
    stroke-linecap: round;
    transform: rotate(90deg);
    transform-origin: center;
    transition: 1s linear all;
    fill-rule: nonzero;
    stroke: currentColor;

    &.green {
      color: rgb(65, 184, 131);
    }

    &.orange {
      color: orange;
    }

    &.red {
      color: red;
    }
  }

  &__label {
    position: absolute;
    width: 90px;
    height: 90px;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 26px;
  }
}
.prepare, .break {
  .test-timer {
    display: inline-block;
    height: unset;
    width: unset;
    svg {
      display: none;
    }
    &__label {
      height: unset;
      width: unset;
      display: inline-block;
      position: relative;
      font-size: 1rem;
    }
  }
}

.break {
  .test-timer__label {
    font-size: 50px;
    font-weight: 700;
  }
}

.section-timer {
  .test-timer {
    height: unset;
    width: unset;
    svg {
      display: none;
    }
    &__label {
      height: unset;
      width: unset;
      font-size: 40px;
      font-weight: 700;
      color: #fff;
    }
  }
}

@media (max-width: 768px) {
  .test {
  .test-timer {
    display: inline-block;
    height: unset;
    width: unset;
    svg {
      display: none;
    }
    &__label {
      height: unset;
      width: unset;
      display: inline-block;
      position: relative;
      font-size: 30px;
      font-weight: 500;
      margin-top: -10px;
    }
  }
}
}

</style>
