<template>
  <div class="sc-popup">
    <div class="sc-popup__inner">
      <div class="sc-popup__text">
        <p>
          Вы уверены, что хотите начать тестирование? После начала тестирования будет запущен таймер отсчета времени, по истечении которого тест будет автоматически завершен.
        </p>
        <p>
          Максимальное общее время тестирования, в которое необходимо уложиться, составляет 160 минут. Дополнительно к времени тестирования предусмотрены два перерыва для отдыха по 10 минут, которые можно пропустить.
        </p>
      </div>
      <div class="sc-popup__buttons">
        <button
          class="button button_secondary"
          @click="$emit('close')"
        >
          Отменить
        </button>
        <button
          class="button"
          @click="$emit('start')"
        >
          Начать
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StartTimerWarningModal"
}
</script>
