import Vue from 'vue'
import VueRouter from 'vue-router'
import TestingComponent from '@/views/TestingComponent.vue'
import PageNotFound from '@/views/PageNotFound.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/testing/:id',
    name: 'Testing',
    component: TestingComponent
  },
  {
    path: '/404',
    name: '404',
    component: PageNotFound
  },
  {
    path: '*',
    redirect: '/404'
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
