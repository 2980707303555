<template>
  <div id="dialog" class="dialog_open">
    <div id='main' class='main_area'></div>
    <div class="testing content" id='dlg-wrap'>
      <div class="dlg-inner">
        <div class="test">
          <div class="test__content">
            <div class="test__info">
              <div>
                <div>Вопрос {{ questionIndex + 1 }} из {{ data.questions.length }}</div>
                <div class="instructions" v-if="data.instructions" @mouseover="instrVisible = true" @mouseleave="instrVisible = false" >
                  <div class="instructions-button">Инструкция</div>
                  <div class="instructions-spoiler" v-if="instrVisible" v-html="data.instructions"></div>
                </div>
              </div>
              <TimerComponent
                  :initialTimeLimit="timeLimit"
                  :initialTimeLeft="timeLeft"
                  @timesUp="postAnswers()"
              />
            </div>
            <div class="test__question" v-if="data.type === 'radio'">
              <div
                  v-if="data.questions[questionIndex].contentType === 'text'"
              >
                <div class="textQuestion-descr" v-if="data.questions[questionIndex].description" v-html="data.questions[questionIndex].description"></div>
                <div class="imageQuestion-task" v-html="data.questions[questionIndex].question"></div>
              </div>
              <div
                  v-if="data.questions[questionIndex].contentType === 'imageRow' || data.questions[questionIndex].contentType === 'imageTable'"
                  :class="{ imageRow : data.questions[questionIndex].contentType === 'imageRow',
                    imageTable : data.questions[questionIndex].contentType === 'imageTable',
                    answerItems2 : data.questions[questionIndex].question.length === 2,
                    answerItems4 : data.questions[questionIndex].question.length === 4,
           }"
              >
                <div class="imageQuestion-descr" v-html="data.questions[questionIndex].description"></div>
                <div class="imageQuestion-task">
                  <div
                      class="imageQuestion-item"
                      v-for="(value, index) in data.questions[questionIndex].question"
                      :key="index"
                  >
                    <div v-if="value !== 'empty'">
                      <img
                          v-bind:src="URL + 'api/testing/file/' + value"
                      >
                    </div>
                    <div v-if="value === 'empty'"
                         :class="{ empty : imageAnswer == null }"
                    >
                      <img
                          v-if="imageAnswer"
                          v-bind:src="URL + 'api/testing/file/' + imageAnswer"
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div
                  v-if="data.questions[questionIndex].contentType === 'imageSingle' || data.questions[questionIndex].contentType === 'imageText'"
                  :class="{ imageSingle : data.questions[questionIndex].contentType === 'imageSingle',
                    imageText : data.questions[questionIndex].contentType === 'imageText',
           }"
              >
                <div class="imageQuestion-descr" v-html="data.questions[questionIndex].description"></div>
                <div class="imageQuestion-task" v-if="data.questions[questionIndex].contentType !== 'imageSingle'">
                  <div
                      class="imageQuestion-item"
                      v-for="(value, index) in data.questions[questionIndex].question"
                      :key="index"
                  >
                    <div>
                      <img
                          v-bind:src="URL + 'api/testing/file/' + value"
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="test__question" v-else>{{ data.questions[questionIndex]}}</div>
            <div class="test__answer">
              <div class="test__answer test__answer_scale" v-if="data.type === 'scale'">
                <div class="answer__wrapper answer__wrapper_scale">
                  <div
                      class="answer-item answer-item_scale"
                      @click="setAnswer(1)"
                      :class="{ active : answers[questionIndex] === 1 }"
                  >1</div>
                  <div
                      class="answer-item answer-item_scale"
                      @click="setAnswer(2)"
                      :class="{ active : answers[questionIndex] === 2 }"
                  >2</div>
                  <div
                      class="answer-item answer-item_scale"
                      @click="setAnswer(3)"
                      :class="{ active : answers[questionIndex] === 3 }"
                  >3</div>
                  <div
                      class="answer-item answer-item_scale"
                      @click="setAnswer(4)"
                      :class="{ active : answers[questionIndex] === 4 }"
                  >4</div>
                  <div
                      class="answer-item answer-item_scale"
                      @click="setAnswer(5)"
                      :class="{ active : answers[questionIndex] === 5 }"
                  >5</div>
                </div>
              </div>
              <div class="test__answer test__answer_yes-no" v-if="data.type === 'yes-no'">
                <div class="answer__wrapper answer__wrapper_yes-no">
                  <div
                      class="answer-item answer-item_yes-no"
                      @click="setAnswer(0)"
                      :class="{ active : answers[questionIndex] === 0 }"
                  >Нет</div>
                  <div
                      class="answer-item answer-item_yes-no"
                      @click="setAnswer(1)"
                      :class="{ active : answers[questionIndex] === 1 }"
                  >Да</div>
                </div>
              </div>
              <div class="test__answer test__answer_radio" v-if="data.type === 'radio'">
                <div
                    class="answer__wrapper answer__wrapper_radio"
                    :class="{ imageSingle : data.questions[questionIndex].contentType === 'imageSingle',
                    IMGQuestion : data.questions[questionIndex].contentType === 'imageRow' || data.questions[questionIndex].contentType === 'imageTable'
           }"
                >
                  <div
                      v-for="(value, index) in data.questions[questionIndex].answers"
                      :key="index"
                      class="answer-item answer-item_radio"
                      @click="setAnswer(index + 1); setImageAnswer(value)"
                      :class="{ active : answers[questionIndex] === (index + 1) }"
                  >
                    <input
                        type="radio"
                        v-bind:value="(index + 1)"
                        v-model="answers[questionIndex]"
                        @click="setAnswer(index + 1); setImageAnswer(value)"
                    >
                    <label>
                <span
                    v-if="data.questions[questionIndex].contentType === 'text' || data.questions[questionIndex].contentType === 'imageText'"
                >{{ value }}</span>
                      <img
                          v-if="data.questions[questionIndex].contentType === 'imageRow'
                  || data.questions[questionIndex].contentType === 'imageTable'
                  || data.questions[questionIndex].contentType === 'imageSingle'
                  "
                          v-bind:src="URL + 'api/testing/file/' + value">
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div class="test__control" :class="{ 'no-nav' : !data.useNavigation }">
            <button
                class="button button_control button_control-prev"
                @click="userAction('prev')"
                v-if="data.useNavigation"
                :class="{ unactive : questionIndex === 0 }"
            >Назад</button>
            <button
                class="button button_control button_control-next"
                @click="userAction('next')"
                :class="{ unactive : !data.useNavigation && answers[questionIndex] === -1 }"
            >{{ questionIndex + 1 === data.questions.length ? "Завершить" : "Вперёд" }}</button>
          </div>
          <div class="test-navigation" v-if="data.useNavigation">
            <div
                class="test-navigation__item"
                v-for="n in data.questions.length"
                :key="n"
                @click="questionIndex = n - 1"
                :class="{ answer : answers[n - 1] !== -1, 'no-answer' : answers[n - 1] === -1, selected : questionIndex === n - 1 }"
            >{{ n }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TimerComponent from '@/components/common/TimerComponent';
import {
  getQuestionIndex,
  getStoredData,
  storeAnswer,
  storeQuestionIndex,
} from '@/helpers/answerUtils.js';

export default {
  name: "PrepareStateComponent",

  components: {
    TimerComponent,
  },

  props: {
    id: {
      type: String,
      default() {
        return '';
      },
      required: true,
    },
    data: {
      type: Object,
      default() {
        return {};
      },
      required: true,
    },
    timeLeft: {
      type: Number,
      default() {
        return 0;
      },
    },
    timeLimit: {
      type: Number,
      default() {
        return 0;
      },
    },
  },

  data() {
    return {
      URL: null,
      questionIndex: 0,
      answers: [],
      imageAnswer: null,
      instrVisible: false,
    };
  },

  mounted() {
    this.setIndex();
    this.setAnswers();
    this.URL = window.location.origin + "/";
  },

  methods: {
    fillArray: function() {
      for (let i = 0; i < this.data.questions.length; i++) {
        this.answers.push(-1);
        storeAnswer(-1, i, this.id);
      }
    },

    setAnswer: function(param) {
      this.answers.splice(this.questionIndex, 1, param);
      storeAnswer(param, this.questionIndex, this.id);
    },

    setImageAnswer: function(image) {
      this.imageAnswer = image;
    },

    userAction: function(action) {
      if (action === "next") {
        if (this.answers[this.questionIndex] !== -1) {
          if (this.questionIndex + 1 < this.data.questions.length) {
            this.questionIndex++;
          } else {
            this.postAnswers();
          }
        }
      }
      if (action === "prev") {
        this.questionIndex--;
      }
      storeQuestionIndex(this.questionIndex, this.id);
      this.resetImageAnswer()
    },

    resetImageAnswer() {
      this.imageAnswer = null;
    },

    setIndex() {
      const index = getQuestionIndex(this.id)
      this.questionIndex = index ? index : 0
    },

    setAnswers() {
      const storedData = getStoredData();
      if (storedData && storedData.id === this.id && storedData.answers.length > 0) {
        this.answers = storedData.answers
      } else {
        this.fillArray()
      }
    },

    postAnswers() {
      this.$emit("post-answers", this.answers);
    }
  },
}
</script>
