const STORAGE_NAME = 'SkillCodeTest';

export function storeAnswer(answer, index, id) {
    const storedData = getStoredData();
    if (storedData && storedData?.id === id) {
        storedData.answers.splice(index, 1, answer);
        storeData(storedData);
    } else {
        storeData({
            id,
            answers: [answer],
        });
    }
}

export function storeQuestionIndex(index, id) {
    const storedData = getStoredData();
    if (storedData && storedData.id === id) {
        storedData.qindex = index;
        storeData(storedData);
    }
}

export function getStoredData() {
    return JSON.parse(localStorage.getItem(STORAGE_NAME))
}

export function storeData(object) {
    return localStorage.setItem(STORAGE_NAME, JSON.stringify(object));
}

export function clearStoredData() {
    localStorage.removeItem(STORAGE_NAME)
}

export function getQuestionIndex(id) {
    const storedData = getStoredData();
    if (storedData && storedData?.id === id) {
        return +storedData.qindex;
    }
    return 0;
}

export function clearQuestionIndex(id) {
    storeQuestionIndex(0, id);
}
