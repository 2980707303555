<template>
  <div class="prepare">
    <h2>Инструкция к тесту</h2>
    <p class="instuctions" v-html="data.instructions"></p>
    <TimerComponent
        :initialTimeLimit="timeLimit"
        :initialTimeLeft="timeLeft"
        @timesUp="startTest()"
    />
    <button class="button centering m-30" @click="startTest">Начать тест</button>
  </div>
</template>

<script>
import TimerComponent from '@/components/common/TimerComponent';

export default {
  name: "PrepareStateComponent",

  components: {
    TimerComponent,
  },

  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
      required: true,
    },
    timeLeft: {
      type: Number,
      default() {
        return 0;
      },
    },
    timeLimit: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
  methods: {
    startTest() {
      this.$emit("start-test");
    }
  }
}
</script>
